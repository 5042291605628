<template>
  <v-app>
    <AppHeaderUser v-if="user" />
    <AppHeader v-else />
    <div
      id="policy_container"
      :style="{ paddingTop: blank ? '60px' : '' }"
      style="height: 100%; width: 100%; background-color: #f7f7f7"
    >
      <v-row v-if="!blank" justify="start" align="center">
        <v-col
          cols="auto"
          class="pointer"
          style="
            margin-top: 40px;
            padding-top: 0;
            padding-left: 25px;
            padding-right: 0;
          "
        >
          <div
            id="policy-back-icon-43be290e"
            @click="$router.back()"
            :class="{ point: hover }"
            style="height: 35px"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
            <Images name="arrow.svg" />
          </div>
        </v-col>
        <v-col cols="auto" style="padding-top: 41px">
          <Texting
            id="policy-back-text-5ecd510c"
            @click.native="$router.back()"
            class="pointer"
            type="p1bold grey2"
            >Back</Texting
          >
        </v-col>
      </v-row>
      <v-row justify="center" style="padding-bottom: 140px">
        <div
          class="d-none d-md-flex"
          style="
            padding-top: 28px;
            padding-bottom: 50px;
            width: 100%;
            margin-left: 34%;
          "
        >
          <Heading type="h1 dark-asphalt">Privacy Policy</Heading>
        </div>
        <div
          class="d-flex d-md-none"
          style="padding-top: 28px; padding-bottom: 50px"
        >
          <Heading type="h1 dark-asphalt">Privacy Policy</Heading>
        </div>
        <v-row
          style="width: 100%"
          class="justify-content-center"
          justify="center"
          v-for="item in items"
          :key="item.text"
        >
          <v-col cols="10" md="4">
            <Texting type="p2 asphalt" v-html="item.text" />
          </v-col>
        </v-row>
      </v-row>
      <v-row
        justify="center"
        justify-md="start"
        style="padding-bottom: 140px"
        v-for="item in terms"
        :key="item.header"
      >
        <v-col
          cols="10"
          md="4"
          class="text-center text-md-left"
          style="padding-top: 5px"
        >
          <Heading type="h3 dark-asphalt" class="ml-md-10 ml-0 pl-md-10 pl-0">{{
            item.header
          }}</Heading>
        </v-col>
        <v-col cols="10" md="4">
          <Texting type="p2 asphalt" v-html="item.content" />
        </v-col>
      </v-row>
    </div>
    <AppFooter />
  </v-app>
</template>

<script>
import Texting from "../components/Texting";
import Heading from "../components/Heading";
import AppHeaderUser from "../components/AppHeaderUser";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import Images from "../components/MyImages";
import { mapState } from "vuex";

export default {
  components: {
    Heading,
    Texting,
    AppHeaderUser,
    Images,
    AppHeader,
    AppFooter,
  },
  computed: {
    ...mapState({
      user: "user",
    }),
  },
  mounted() {
    this.$nextTick(() => {
      let element = document.getElementById("policy_container");
      this.$scrollTo(element, 100, { offset: -100, easing: "linear" });
    });
    if (this.$route.query.blank === "true") {
      this.blank = true;
    }
  },
  data: () => ({
    hover: false,
    loading: false,
    blank: false,
    terms: [
      {
        header: "Information Collection & Use",
        content:
          "We collect several different types of information for various purposes to provide and" +
          " improve our Service to you.<br><br>" +
          "<b>General Data Protection Regulation (GDPR)</b><br><br>" +
          "Perkfection’s legal basis for collecting and using the personal information described in " +
          "this Privacy Policy depends on the Personal Information we collect and the specific context " +
          "in which we collect the information:<br><br>" +
          "<ul>\n" +
          "  <li>Perkfection needs to perform establish a contract with you</li>\n" +
          "  <li>You have given Perkfection permission to do so</li>\n" +
          "  <li>Processing your personal information is in Perkfection legitimate interests</li>\n" +
          "  <li>Perkfection needs to comply with the law</li>\n" +
          "  <li>Perkfection will retain your personal information only for as long as is necessary for " +
          "the purposes set out in this Privacy Policy. We will retain and use your information " +
          "to the extent necessary to comply with our legal obligations, resolve disputes, " +
          "and enforce our policies.\n</li>\n" +
          "</ul><br>" +
          "If you are a resident of the European Economic Area (EEA), you have certain data protection" +
          " rights. If you wish to be informed what Personal Information we hold about you and if " +
          "you want it to be removed from our system, please contact us.<br><br>" +
          "Under predefined circumstances, you have the following data protection rights:<br><br>" +
          "<ul>\n" +
          "  <li>The right to access, update or delete the information we have regarding you,</li>\n" +
          "  <li>The right of rectification,</li>\n" +
          "  <li>The right to object,</li>\n" +
          "  <li>The right of restriction,</li>\n" +
          "  <li>The right to data portability,</li>\n" +
          "  <li>The right to withdraw consent.</li>\n" +
          "</ul>",
      },
      {
        header: "Personal Data",
        content:
          "While using our Service, we may ask you to provide us with certain personally " +
          'identifiable information that can be used to contact or identify you ("Personal Data"). ' +
          "Personally identifiable information may include, but is not limited to:<br><br>" +
          "Email address\n<br>" +
          "Display Name\n<br>" +
          "Phone number\n<br>" +
          "Cookies and Usage Data\n",
      },
      {
        header: "Usage Data",
        content:
          'We may also collect information on how the Service is accessed and used ("Usage Data"). ' +
          "This Usage Data may include information such as your computer's Internet Protocol " +
          "address (e.g. IP address), browser type, browser version, the pages of our Service " +
          "that you visit, the time and date of your visit, the time spent on those pages, " +
          "unique device identifiers and other diagnostic data.\n",
      },
      {
        header: "Tracking & Cookies Data",
        content:
          "We use cookies and similar tracking technologies to track the activity on our " +
          "Service and hold certain information.<br>" +
          "Cookies are files with a small amount of data which may include an anonymous unique " +
          "identifier. Cookies are sent to your browser from a website and stored on your device. " +
          "Tracking technologies also used are beacons, tags, and scripts to collect and track " +
          "information and to improve and analyze our Service.<br><br>" +
          "You can instruct your browser to refuse all cookies or to indicate when a cookie is " +
          "being sent. However, if you do not accept cookies, you may not be able to use some " +
          "portions of our Service. You can learn more how to manage cookies in the " +
          '<a href="https://privacypolicies.com/blog/how-to-delete-cookies/">Browser Cookies Guide</a>.' +
          "<br><br>Examples of Cookies we use:<br><br>" +
          "<ul>\n" +
          "  <li><b>Session Cookies.</b> We use Session Cookies to operate our Service.</li>\n" +
          "  <li><b>Preference Cookies.</b> We use Preference Cookies to remember your preferences and various settings.</li>\n" +
          "  <li><b>Security Cookies.</b> We use Security Cookies for security purposes.</li>\n" +
          "</ul>",
      },
      {
        header: "Use of Data",
        content:
          "Perkfection uses the collected data for various purposes:<br><br>" +
          "<ul>\n" +
          "  <li>To provide and maintain the Service</li>\n" +
          "  <li>To notify you about changes to our Service</li>\n" +
          "  <li>To allow you to participate in interactive features of our Service when you choose to do so</li>\n" +
          "  <li>To provide customer care and support</li>\n" +
          "  <li>To provide analysis or valuable information so that we can improve the Service</li>\n" +
          "  <li>To monitor the usage of the Service</li>\n" +
          "  <li>To detect, prevent and address technical issues</li>\n" +
          "</ul>",
      },
      {
        header: "Transfer of Data",
        content:
          "Your information, including Personal Data, may be transferred to — and maintained " +
          "on — computers located outside of your state, province, country or other governmental " +
          "jurisdiction where the data protection laws may differ than those from your jurisdiction.<br><br>" +
          "If you are located outside Armenia and choose to provide information to us, please note " +
          "that we transfer the data, including Personal Data, to Armenia and process it there.\n<br>" +
          "Your consent to this Privacy Policy followed by your submission of such information " +
          "represents your agreement to that transfer.\n<br>" +
          "Perkfection will take all steps reasonably necessary to ensure that your data is treated " +
          "securely and in accordance with this Privacy Policy and no transfer of your Personal " +
          "Data will take place to an organization or a country unless there are adequate controls " +
          "in place including the security of your data and other personal information.",
      },
      {
        header: "Disclosure of Data",
        content:
          "Legal Requirements<br><br>" +
          "Perkfection may disclose your Personal Data in the good faith belief that " +
          "such action is necessary to:<br><br>" +
          "<ul>\n" +
          "  <li>To comply with a legal obligation</li>\n" +
          "  <li>To protect and defend the rights or property of Perkfection</li>\n" +
          "  <li>To prevent or investigate possible wrongdoing in connection with the Service</li>\n" +
          "  <li>To protect the personal safety of users of the Service or the public</li>\n" +
          "  <li>To protect against legal liability</li>\n" +
          "</ul>",
      },
      {
        header: "Security Of Data",
        content:
          "The security of your data is important to us, but remember that no method of transmission " +
          "over the Internet, or method of electronic storage is 100% secure. While we strive to " +
          "use commercially acceptable means to protect your Personal Data, we cannot guarantee " +
          "its absolute security.",
      },
      {
        header: "Service Providers",
        content:
          "We may employ third party companies and individuals to facilitate our Service " +
          '("Service Providers"), to provide the Service on our behalf, to perform Service-related ' +
          "services or to assist us in analyzing how our Service is used.<br><br>" +
          "These third parties have access to your Personal Data only to perform these tasks on " +
          "our behalf and are obligated not to disclose or use it for any other purpose.",
      },
      {
        header: "Links to Other Sites",
        content:
          "Our Service may contain links to other sites that are not operated by us. If you click " +
          "on a third party link, you will be directed to that third party's site. We strongly " +
          "advise you to review the Privacy Policy of every site you visit.<br><br>" +
          "We have no control over and assume no responsibility for the content, privacy policies " +
          "or practices of any third party sites or services.",
      },
      {
        header: "Children's Privacy",
        content:
          'Our Service does not address anyone under the age of 18 ("Children").<br><br>' +
          "We do not knowingly collect personally identifiable information from anyone under the " +
          "age of 18. If you are a parent or guardian and you are aware that your Children has " +
          "provided us with Personal Data, please contact us. If we become aware that we have " +
          "collected Personal Data from children without verification of parental consent, we take " +
          "steps to remove that information from our servers.",
      },
      {
        header: "Contact Information",
        content:
          "If you have any questions about this Privacy Policy, please contact us by email: info@perkfection.com",
      },
    ],
    items: [
      {
        text:
          "At perkfection.com, accessible from https://perkfection.com, hereafter referred to as “the Website,” " +
          "one of our main priorities is the privacy of our visitors. This Privacy Policy document contains details " +
          "on the type of information that is collected and recorded by perkfection.com and its usage.",
      },
      {
        text:
          "We use your data to provide and improve the Service. By using the Service, you agree to the collection " +
          "and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the " +
          "terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from perkfection.com.",
      },
      {
        text: "If you have any additional questions or need more information about our Privacy Policy, do not hesitate to contact us.",
      },
    ],
  }),
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Always have just enough rewards to motivate immediate purchase ✓Limitless stock refills ✓Indiegogo and Kickstarter support ✓Flexible pricing.",
      },
      {
        property: "og:description",
        content:
          "Always have just enough rewards to motivate immediate purchase ✓Limitless stock refills ✓Indiegogo and Kickstarter support ✓Flexible pricing.",
      },
      {
        name: "keywords",
        content: "kickstarter rewards ideas, indiegogo perk ideas",
      },
    ],
  },
};
</script>
